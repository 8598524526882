
import { Component, Prop, Vue } from "vue-property-decorator";
import Card from '@/components/GiveawayDetailCard.vue';
@Component({
  components: {
    Card
  }
})
export default class PostDetail extends Vue {
  @Prop({default: true}) active!: boolean;
  @Prop() text!: string;
  private main_img = require("@/assets/images/new-notification.png");

  handleClick () {
    this.sharePost()
  }

  async sharePost() {
				let input = document.querySelector('#url-input') as  HTMLInputElement;
				input?.setAttribute('type', 'text');
				input.select()
				try {
					var successful = document.execCommand('copy');
					this.$toast.success('لینک کپی شد، اکنون میتوانید آن را برای دیگران ارسال کنید.')
				} catch (err) {
          console.log(err);
          
					this.$toast.error('لطفا دوباره تلاش کنید.')
				}
				input.setAttribute('type', 'hidden')
				window.getSelection()?.removeAllRanges()
		}
}

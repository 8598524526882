
import { Component, Vue } from 'vue-property-decorator';
import GoToArchive from '../components/GoToArchive.vue'
import GoToNewGiveaway from '../components/GoToNewGiveaway.vue'
import GiveawayDetail from '../components/GiveawayDetail.vue'
import PostDetail from '../components/PostDetail.vue'
import { GetUserGiveawayResponse } from '@/plugins/api';
import { date } from '@/mixins/date';
@Component({
  components: {
      GoToArchive,
      GoToNewGiveaway,
      GiveawayDetail,
      PostDetail,
  },
})
export default class LatestGiveawayPage extends Vue {
    private giveaway: GetUserGiveawayResponse = {
        model_id: null,
        give_id: null,
        creator_id: 'در حال دریافت اطلاعات از سرور...',
        prize: 'در حال دریافت اطلاعات از سرور...',
        description: 'در حال دریافت اطلاعات از سرور...',
        type: '',
        status: 1,
        post_url: '',
        shortcode: '',
        total_enrolled: 0,
        total_verified: 0,
        chances: 0,
        ends_at: 0,
        winners: [],
        crowled_at: 0,
        createdAt: Math.ceil(new Date().getTime() * 0.001)
    };
    private giveaway_text = 'در حال بارگزاری...';
    private post_text = 'در حال بارگزاری...';
    private loading = false;
    private refresh_sent = false;

    private url = process.env.VUE_APP_BASE_LINK;

    setupGiveawayDetailCards (): void{
        this.giveaway_text =    
        `نوع قرعه‌کشی:   ${this.giveaway.description}\nتعداد برندگان:  ${this.giveaway.chances}\nتاریخ برگزاری:  ${date(this.giveaway.ends_at, 'dd MMMM yyyy ')}`;
        if (this.giveaway.status==0) this.giveaway_text +=
        '\nمنتظر تایید پرداخت (میانگین زمان تایید ۵ دقیقه)'

        this.post_text =    
        `تعداد دنبال کنندگان:   ${this.giveaway.post?.follower_count||'بررسی نشده'}\nتعداد لایک:  ${this.giveaway.post?.likes_count||'بررسی نشده'}\nتعداد کامنت ها:  ${this.giveaway.post?.comments_count||'بررسی نشده'}`;
    }

    async getLatestGiveaway (): Promise<void>{
        this.loading = true;
        this.$api.setToken(this.$store.getters.CUSTOMER_TOKEN);
        try {
            const res = await this.$api.getMyLatestGiveaway();
            this.giveaway = res.data;
            if (this.giveaway.model_id) {
                const resolved = this.$router.resolve({name: 'rolling-giveaway', params: {give_id: this.giveaway.model_id}})
                this.url = `${process.env.VUE_APP_BASE_LINK}${resolved.href}`
            }
            this.setupGiveawayDetailCards();
        } catch (err) {
            console.log(err);
        }
        this.loading = false;
    }

    async onGiveawayClicked (e: Event): Promise<void> {
        e.preventDefault()
        if (!this.giveaway.give_id) return;
        if (this.giveaway.status==0) {
            try {
                await this.$api.checkPayment({give_id: this.giveaway.give_id});
            } catch (err) {
                console.log(err);
            }
        } else {
            this.$router.replace(`/giveaway/${this.giveaway.model_id}`)
        }
        
    }

    get buttonIsDisabled(): boolean {
        return this.giveaway.status!==0||(((new Date().getTime()*0.001)-this.giveaway.createdAt))  < 180;
    }

    mounted (): void{
        this.$socket.client.emit('message', (res: any) => {
            console.log(res);
        });
        this.$socket.$subscribe('give-activated', (payload: any) => {
            console.log('activated', payload);
            console.log(payload.model_id, this.giveaway.model_id);
            
          if (payload.model_id === this.giveaway.model_id) {
              this.giveaway.status = 1;
              this.$router.replace(`/giveaway/${payload.model_id}`)
          }
        });
        this.getLatestGiveaway()
    }

    beforeDestroy (): void {
      this.$socket.$unsubscribe('give-activated');
    }

    
}


import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class GiveawayDetailCard extends Vue {
  @Prop({default: true}) active!: boolean;
  @Prop({default: false}) buttonDisabled!: boolean;
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @Prop() text!: string;
  @Prop() img!: string;
  @Prop() mainImg!: string;
  @Prop({
      default: "مشاهده جزئیات"
  }) buttonText!: string;
  @Prop() to!: string;
  @Prop() href!: string;
  @Prop() onClick?: (event: Event) => (void);

  handler (e: Event): void {
    this.$emit("click", e);
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import Card from '@/components/LittleNavCard.vue';
@Component({
  components: {
    Card
  }
})
export default class GoToNewGiveaway extends Vue {
  @Prop({default: true}) active!: boolean;
  private img = require("@/modules/Profile/assets/new_giveaway.png");
}

import { render, staticRenderFns } from "./GoToNewGiveaway.vue?vue&type=template&id=506ab754"
import script from "./GoToNewGiveaway.vue?vue&type=script&lang=ts"
export * from "./GoToNewGiveaway.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

import { Component, Prop, Vue } from "vue-property-decorator";
import Card from '@/components/LittleNavCard.vue';
@Component({
  components: {
    Card
  }
})
export default class GoToArchive extends Vue {
  @Prop({default: true}) active!: boolean;
  private img = require("@/modules/Profile/assets/done_giveaways.png");
}


import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class LittleNavCard extends Vue {
  @Prop({default: true}) active!: boolean;
  @Prop() title!: string;
  @Prop() img!: string;
  @Prop({
      default: "مشاهده جزئیات"
  }) buttonText!: string;
  @Prop() to!: string;
}

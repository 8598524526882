
import { Component, Prop, Vue } from "vue-property-decorator";
import Card from '@/components/GiveawayDetailCard.vue';
@Component({
  components: {
    Card
  }
})
export default class GiveawayDetail extends Vue {
  @Prop({default: true}) active!: boolean;
  @Prop({default: false}) buttonDisabled!: boolean;
  @Prop() text!: string;
  @Prop({default: 'مشاهده جزئیات'}) buttonText!: string;
  @Prop() to?: string;
  @Prop() mainImage?: string;
  private img = require("@/assets/images/instagram.png");
  private main_img = null;

  handler (e: Event): void {
    this.$emit('click', e)
  }

  mounted () {
    this.main_img = this.mainImage?encodeURI(this.mainImage):require("@/assets/images/picture.png");
  }
}
